@mixin ContactModal() {
	.contact-modal {
		position: fixed;
		top: 0;
		left: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
		padding: 24px;
		padding-bottom: 0;
		background-color: rgba(#1a1919, 0.5);
		z-index: 1000;
		opacity: 0;
		transform: scale(1.2);
		transition: opacity 0.2s 0s ease-in-out, transform 0.2s 0s ease-in-out;
		pointer-events: none;

		&.active {
			opacity: 1;
			transform: scale(1);
			pointer-events: all;
		}

		.modal-content {
			position: relative;
			padding: 40px 64px;
			max-width: 900px;
			width: 100%;
			max-height: 100%;
			background-color: #fff;
      color: #1a1919;
			overflow-y: auto;

			.close-icon {
				position: absolute;
				top: 24px;
				right: 32px;
				width: 30px;
				height: 30px;
				cursor: pointer;
			}

			.title {
				font-size: 36px;
				font-weight: bold;
				margin-bottom: 45px;
				text-align: center;
			}

			.contact-form {
				display: flex;
				flex-direction: column;
				gap: 45px;

				input,
				select,
				textarea {
					padding: 16px;
					font-size: 16px;
					background-color: transparent;
					border: .0625rem solid #eaeaea;
					transition: 0.3s ease-in-out border;

					&:focus {
						border-color: #000000;
					}
				}

        .other-input {
          border: none;
          font-size: 14px;
          padding: 0;
          display: none;

          &.active {
            display: block;
          }
        }

				textarea {
					resize: none;
					padding-bottom: 80px;
				}

				.input-row {
					display: flex;
					gap: 16px;
					width: 100%;
				}

				.input-wrapper {
					display: flex;
					flex-direction: column;
					gap: 8px;
					flex: 1;

					.input-label {
						font-size: 12px;
					}
				}

				.sender-data {
					display: flex;
					flex-direction: column;
					gap: 16px;
				}

				.interested-in {
					.interested-in-label {
						font-size: 12px;
						margin-bottom: 16px;
						display: block;
					}
					.checkboxes {
						display: grid;
						grid-template-columns: repeat(2, 1fr);
						gap: 12px 32px;

						.checkbox-wrapper {
							display: flex;
							gap: 8px;

							input[type="checkbox"] {
								width: 20px;
								height: 20px;
							}
							label {
								flex: 1;
                font-size: 14px;
                font-weight: 500;

                &.other-label {
                  &.hidden {
                    display: none;
                  }
                }
							}
						}
					}
				}
				.submit-button {
					padding: 24px 48px;
					font-size: 16px;
					font-weight: bold;
					text-transform: uppercase;
					color: #fff;
					background: linear-gradient(96.22deg, #4cc3c6 0%, #1ab0b4 100%);
					border-radius: 40px;
					width: fit-content;
					margin: 0 auto;
				}
			}
		}

		@include media-breakpoint-down(sm) {
			.modal-content {
				padding: 40px 32px;

				.contact-form {
					.input-row {
						flex-direction: column;
					}
				}
			}
		}
		@include media-breakpoint-down(xs) {
			padding: 0;

			.modal-content {
				max-height: 100%;
				padding: 24px 16px;

				.title {
					font-size: 28.8px;
					margin-bottom: 40px;
				}

				.contact-form {
					.interested-in {
						.checkboxes {
							grid-template-columns: 1fr;
						}
					}
				}
			}
		}
	}
}
