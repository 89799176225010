@import '../mixins';
@import '../variables';
@import './web3/about-us';
@import './web3/capabilities';
@import './web3/careers';
@import './web3/development';
@import './web3/future';
@import './web3/gaming-portal';
@import './web3/ideas-realised';
@import './web3/intro';
@import './web3/nfts';
@import './web3/projects';
@import './web3/testimonials';
@import './web3/contact-modal';

.web3-wrapper {
  .bottom-left-lines {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 26.9375rem;
    height: 19.875rem;
  }
  
  @include Intro();
  @include Capabilities();
  @include IdeasRealised();
  @include Projects();
  @include Development();
  @include GamingPortal();
  @include NFTs();
  @include Future();
  @include Testimonials();
  @include AboutUs();
  @include Careers();
  @include ContactModal();
}