@mixin Capabilities() {
	.capabilities-wrapper {
		position: relative;
		padding-top: 6.625rem;
		padding-bottom: 14.625rem;

		.capabilities-subtitle {
			margin-bottom: 6.5rem;
		}

		.capabilities {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			gap: 4.5rem 2rem;

			.capability {
				padding-bottom: 1.5rem;
				border-bottom: 1px solid #4cc3c6;

				.capability-title {
					margin-bottom: 0.3125rem;
					font-size: 1.75rem;
					font-weight: bold;
				}
				.capability-text {
					font-size: 1rem;
					font-weight: 500;
					opacity: 0.4;
				}
			}
		}

		.capabilities-dots {
			position: absolute;
			bottom: 0;
			left: 0;
			width: 100vw;
		}

		.capabilities-circles {
			position: absolute;
			top: 3.125rem;
			right: 0;
			width: 7.9375rem;
			height: auto;
		}

		@include media-breakpoint-down(xs) {
			padding-bottom: 8rem;

			.capabilities {
				grid-template-columns: 1fr;
				gap: 3.5rem;

				.capability {
					.capability-title {
						font-size: 1.5rem;
					}
				}
			}

			.capabilities-subtitle {
				margin-bottom: 5rem;
			}

			.capabilities-circles {
				width: 5rem;
			}
		}
	}
}
