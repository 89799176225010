@mixin GamingPortal() {
	.gaming-portal-wrapper {
		position: relative;

		.gaming-portal-subtitle {
			margin-bottom: 2rem;
		}
		.title {
			margin-bottom: 2rem;
		}
		.text {
			margin-bottom: 3rem;
			max-width: 32rem;
		}
		.image-wrapper {
			&::after {
				z-index: 0;
				content: "";
				display: block;
				position: absolute;
				bottom: 0;
				left: 0;
				height: 18.4rem;
				width: 100%;
				background-color: #4cc3c6;
			}

			.image {
				width: 100%;
				height: 36.8rem;
				position: relative;
				z-index: 1;
			}
		}
		@include media-breakpoint-down(md) {
			.gaming-portal-subtitle {
				margin-bottom: 1.5rem;
			}

			.title {
				font-size: 3rem;
				margin-bottom: 1.8rem;
			}

			.text {
				margin-bottom: 2rem;
			}

			.image-wrapper {
				.image {
					height: 30rem;
				}
				&::after {
					height: 15rem;
				}
			}
		}
		@include media-breakpoint-down(xs) {
			padding-top: 3rem;
			
			.gaming-portal-subtitle {
				margin-bottom: 1.5rem;
			}
			.title {
				font-size: 2rem;
			}

			.image-wrapper {
				.image {
					height: auto;
				}
				&::after {
					height: 4.5rem;
				}
			}
		}
	}
}
