@mixin Intro() {
	.web3-intro {
		position: relative;
		display: flex;
		align-items: center;
		padding-top: 5rem;
		min-height: calc(100vh - 7rem);

		.lines-background {
			position: absolute;
			display: block;
			top: -12.3rem;
			width: 100vw;
			height: auto;
			z-index: -1;
		}

		.shapes-background {
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
			width: 22rem;
			height: auto;

			#dot {
				@keyframes intro-dot-animation {
					0% {
						transform: translate(-0.35rem, -0.55rem);
					}
					50% {
						transform: translate(.35rem, .55rem);
					}
					100% {
						transform: translate(-0.35rem, -0.55rem);
					}
				}

				animation-name: intro-dot-animation;
				animation-duration: 3s;
				animation-iteration-count: infinite;
			}
		}

		.intro-link {
			display: flex;
			align-items: center;
			gap: 15px;
			width: fit-content;

			padding: 1.5rem;
			border-radius: 3rem;

			background: linear-gradient(96.22deg, #4cc3c6 0%, #1ab0b4 100%);
			font-size: 1rem;
			font-weight: 700;
			text-decoration: none;
			color: inherit;
			text-transform: uppercase;
			cursor: pointer;

			img {
				width: 0.6rem;
				transition: .3s ease-in-out all;
			}

			&:hover {
				img {
					transform: translateY(0.2rem);
				}
			}
		}

		@include media-breakpoint-down(md) {
			.shapes-background {
				top: unset;
				bottom: 0;
				transform: unset;
				width: 15rem;
			}
		}

		@include media-breakpoint-down(sm) {
			align-items: center;

			.web3-intro-subtitle {
				font-size: 0.9rem;
			}

			.web3-intro-title {
				font-size: 1.8rem;
				max-width: 31.25rem;
				margin-bottom: 1.875rem;
			}
		}
		@include media-breakpoint-down(xs) {
			align-items: flex-start;

			.intro-link {
				padding: 1.2rem 1.5rem;
			}

			.shapes-background {
				width: 11rem;
			}
		}
	}
}
