@mixin Careers() {
	.careers-wrapper {
		position: relative;

		.circles {
			position: absolute;
			top: 0;
			right: 0;
			width: 14.375rem;
			height: auto;
		}

		.careers {
			.careers-content {
				padding: 4.6rem 0;
				color: #1A1919;
				background-color: #ebf2f2;

				.content-title {
					font-size: 2.5rem;
					text-align: center;
					max-width: 31.25rem;
					font-weight: bold;
					margin: 0 auto;
					margin-bottom: 2.5rem;
				}

				.content-button {
					display: block;
					padding: 1.4rem 3rem;
					margin: 0 auto;
					text-transform: uppercase;
					color: #fff;
					border: none;
					font-size: 1rem;
					background: linear-gradient(96.22deg, #4cc3c6 0%, #1ab0b4 100%);
					border-radius: 4.5rem;
					width: fit-content;
					transition: .3s ease-in-out all;

					&:hover {
						transform: translateY(-.3125rem);
					}
				}
			}
		}

		.careers-footer {
			padding: 2rem 0;
			text-align: center;
			background-color: #fff;
			color: #1A1919;
			border-bottom: 1px solid #ebf2f2;

			.footer-text {
				margin-bottom: 0.5rem;
			}

			.footer-link {
				display: flex;
				align-items: center;
				gap: .3rem;
				width: fit-content;
				margin: 0 auto;
				font-size: 1rem;
				font-weight: bold;
				color: inherit;
				text-decoration: none;
				border-bottom: 2px solid #1A1919;
				transition: .3s ease-in-out all;

				svg {
					transition: .3s ease-in-out all;
				}

				&:hover {
					color: #4CC3C6;
					border-bottom-color: #4CC3C6;

					svg {
						transform: translateX(0.3125rem);
					}
				}
			}
		}
		@include media-breakpoint-down(xs) {
			.circles {
				width: 6rem;
				top: unset;
				bottom: 50%;
				transform: translateY(50%);
			}

			.careers {
				.careers-content {
					padding: 3rem 20px;

					.content-title {
						font-size: 1.5rem;
						margin-bottom: 1.8rem;
					}

					.content-link {
						padding: 1rem 2rem;
						font-size: .875rem;
					}
				}
				.careers-footer {
					padding: 2rem 20px;

					.footer-text {
						font-size: .875rem;
						margin-bottom: 1rem;
					}
				}
			}
		}
	}
}
