@mixin IdeasRealised() {
	.ideas-realised-wrapper {
		position: relative;
		padding: 20.1875rem 0;

		.ideas-realised-shapes {
			position: absolute;
			bottom: 0;
			right: 0;
			width: 34rem;
			height: auto;

			#dot {
				@keyframes ideas-dot-animation {
					0% {
						transform: translateY(-0.45rem);
					}
					50% {
						transform: translateY(.45rem);
					}
					100% {
						transform: translateY(-0.45rem);
					}
				}

				animation-name: ideas-dot-animation;
				animation-duration: 3s;
				animation-iteration-count: infinite;
			}
		}

		.ideas-realised-subtitle {
			margin-bottom: 2.6875rem;
		}

		.ideas-realised-title {
			margin-bottom: 1.3125rem;
			font-size: 4rem;
			font-weight: bold;
		}

		.ideas-realised-text-big {
			font-size: 1.5rem;
			font-weight: 500;
			margin-bottom: 3rem;
			color: #4cc3c6;
			max-width: 34.375rem;
		}

		.ideas-realised-text {
			font-size: 1.125rem;
			line-height: 2rem;
			max-width: 35rem;
		}

		@include media-breakpoint-down(md) {
			padding: 12rem 0;

			.ideas-realised-shapes {
				width: 20rem;
			}

			.ideas-realised-title {
				font-size: 3rem;
			}
			.ideas-realised-text-big {
				font-size: 1.3rem;
				margin-bottom: 2rem;
			}
		}
		@include media-breakpoint-down(xs) {
			padding: 8rem 0;
			
			.ideas-realised-shapes {
				display: none;
			}
			.ideas-realised-title {
				font-size: 2rem;
			}
		}
	}
}
