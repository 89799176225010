@mixin NFTs() {
	.nfts-wrapper {
		padding-top: 3.5rem;
		padding-bottom: 7.5rem;
		background-color: #4cc3c6;
		color: #fff;

		.expertise {
			display: flex;
			gap: 4.875rem;

			.expertise-item {
				.item-title {
					font-size: 1.5rem;
					font-weight: bold;
					margin-bottom: 1rem;
				}
				.item-text {
					font-size: 1.125rem;
					margin-bottom: 0;
				}
			}
		}
		@include media-breakpoint-down(xs) {
			padding-top: 2.5rem;

			.expertise {
				flex-direction: column;
				gap: 3rem;
			}
		}
	}
}
