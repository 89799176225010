@mixin Future() {
	.future-wrapper {
		position: relative;
		padding: 13rem 0;

		.container {
			position: relative;
			z-index: 1;
	}

		.cube {
			position: absolute;
			top: 0;
			right: 0;
			z-index: 0;
			width: 20rem;
			height: auto;

			#dot {
				@keyframes future-dot-animation {
					0% {
						transform: translateY(-0.9rem);
					}
					50% {
						transform: translateY(.9rem);
					}
					100% {
						transform: translateY(-0.9rem);
					}
				}

				animation-name: future-dot-animation;
				animation-duration: 3s;
				animation-iteration-count: infinite;
			}
		}
		.shapes {
			position: absolute;
			bottom: 0;
			right: 0;
			z-index: 0;
			width: 35.5rem;
			height: auto;
		}

		.future-subtitle {
			margin-bottom: 2.6875rem;
		}

		.title {
			font-size: 1.75rem;
			max-width: 45.25rem;
			margin-bottom: 8rem;
		}

		.stats {
			display: flex;
			flex-direction: column;
			gap: 3.125rem;

			.stats-row {
				display: flex;
				gap: 3.125rem 3.75rem;
			}

			.stats-item {
				.item-title {
					font-size: 3.5rem;
					font-weight: bold;
					margin-bottom: 0;
				}
				.item-text {
					font-size: 1.25rem;
					color: #4cc3c6;
				}
			}
		}
		@include media-breakpoint-down(md) {
			.cube {
				width: 12rem;
			}

			.shapes {
				width: 25rem;
			}

			.title {
				margin-bottom: 5rem;
			}

			.stats {
				gap: 2rem;
		
				.stats-row {
					gap: 2rem;
				}

				.stats-item {
					.item-title {
						font-size: 2.5rem;
					}
					.item-text {
						font-size: 1rem;
					}
				}
			}
		}
		@include media-breakpoint-down(sm) {
			.shapes {
				width: 20rem;
			}
		}
		@include media-breakpoint-down(xs) {
			padding-top: 8rem;
			padding-bottom: 12rem;

			.cube {
				width: 8rem;
			}
			
			.shapes {
				width: 15rem;
			}

			.bottom-left-lines {
				display: none;
			}
			
			.title {
				font-size: 1.5rem;
				margin-bottom: 4rem;
			}

			.stats {
				.stats-row {
					flex-direction: column;
				}

				.stats-item {
					.item-title {
						font-size: 1.5rem;
					}
				}
			}
		}
	}
}
