@mixin AboutUs() {
	.about-us-wrapper {
		position: relative;
		padding-top: 15.1875rem;
		padding-bottom: 17.5rem;

		.about-us {
			display: flex;
			gap: 1.6875rem;
			padding-left: 10rem;

			.content {
				flex: 1;

				.about-us-subtitle {
					margin-bottom: 2.6875rem;
				}

				.title {
					font-size: 1.75rem;
					font-weight: bold;
					margin-bottom: 2.5rem;
				}

				.text {
					margin-bottom: 2rem;
				}
			}

			.image-wrapper {
				width: 37.625rem;
				position: relative;
				padding: 2.5rem;
				flex: 1;

				.image-decoration-left, .image-decoration-right {
					position: absolute;
					z-index: -1;
					width: 18.75rem;
					height: 18.75rem;
				}
				.image-decoration-left {
					top: 0;
					left: 0;
				}
				.image-decoration-right {
					bottom: 0;
					right: 0;
				}
			}
		}
		@include media-breakpoint-down(md) {
			padding-top: 2rem;
			padding-bottom: 8rem;

			.about-us {
				flex-direction: column-reverse;
				padding-left: 4rem;
				gap: 3rem;
				
				.content {
					max-width: 50rem;
				}

				.image-wrapper {
					max-height: 400px;
					margin: 0 auto;
				}
			}
		}
		@include media-breakpoint-down(sm) {
			.about-us {
				padding: 0 20px;
				
				.content {
					.title {
						font-size: 1.5rem;
						margin-bottom: 1.5rem;
					}
					.text {
						margin-bottom: 1.5rem;
					}
				}
				
				.image-wrapper {
					max-height: unset;
					max-width: 25.75rem;
					padding: 2rem;

					.image-decoration-left, .image-decoration-right {
						width: 12rem;
						height: 12rem;
					}
				}
			}
		}
		@include media-breakpoint-down(xs) {
			padding-bottom: 4rem;
			
			.about-us {
				.image-wrapper {
					max-width: 100%;
					padding: 1rem;

					.image-decoration-left, .image-decoration-right {
						width: 10rem;
						height: 10rem;
					}
				}
			}
		}
	}
}
