@mixin Projects() {
	.projects-wrapper {
		padding-top: 17.9375rem;

		.projects-subtitle {
			margin-bottom: 2rem;
		}

		.project-name {
			margin-bottom: 5rem;
			font-size: 4rem;
			font-weight: bold;
		}

		.project-image-wrapper {
			position: relative;

			.project-dots {
				position: absolute;
				bottom: 5rem;
				left: 0;
				width: 100%;
				z-index: -1;
			}

			&::after {
				z-index: -1;
				content: "";
				display: block;
				position: absolute;
				bottom: 0;
				left: 0;
				height: 5rem;
				width: 100%;
				background-color: #f5f5f5;
			}
		}

		.project-details-wrapper {
			padding-top: 5rem;
			background-color: #f5f5f5;
			color: #1A1919;

			.project-details {
				display: flex;
				gap: 5rem;

				.details-column {
					display: flex;
					flex-direction: column;
					flex: 1;
					gap: 3.625rem;

					.tech-stack {
						.tech-stack-subtitle {
							font-size: 1.125rem;
							margin-bottom: 0.75rem;
						}
						.tech-stack-title {
							font-size: 1.5rem;
							text-transform: unset;
							margin-bottom: 0;
						}
					}

					.column-text {
						p:first-child {
							margin-bottom: 1.25rem;
						}
					}

					.project-link {
						display: flex;
						align-items: center;
						gap: .625rem;
						font-size: 1.625rem;
						font-weight: bold;
						text-decoration: none;
						color: #6d0dff;

						svg {
							transition: transform 0.3s ease-in-out;
						}

						&:hover {
							svg {
								transform: translateX(0.5rem);
							}
						}
					}
				}
			}
		}
		@include media-breakpoint-down(md) {
			padding-top: 10rem;

			.projects-subtitle {
				margin-bottom: 1.2rem;
			}

			.project-name {
				font-size: 3rem;
				margin-bottom: 3.5rem;
			}

			.project-details-wrapper {
				.project-details {
					padding-bottom: 4rem;
				}
			}
		}
		@include media-breakpoint-down(xs) {
			padding-top: 3rem;

			.project-name {
				font-size: 2rem;
				margin-bottom: 2.5rem;
			}
			.project-details-wrapper {
				padding-top: 4rem;

				.project-details {
					flex-direction: column;
					padding-top: 0;

					.details-column {
						.project-logo {
							display: block;
							max-width: 18.75rem;
							margin: 0 auto;
						}

						.tech-stack {
							.tech-stack-subtitle {
								font-size: 1rem;
								margin-bottom: 1rem;
							}
							.tech-stack-title {
								font-size: 1.25rem;
							}
						}

						.project-link {
							font-size: 1.5rem;
						}
					}
				}
			}
		}
	}
}
