@mixin Development() {
	.development-wrapper {
		position: relative;
		padding-top: 8.125rem;
		padding-bottom: 10.625rem;

		.projects {
			display: flex;
			align-items: stretch;
			gap: 2rem;

			.project {
				display: flex;
				flex-direction: column;
				flex: 1;

				.project-image {
					margin-bottom: 1.4375rem;
					width: 100%;
					height: auto;

					#middle {
						@keyframes middle-animation {
							0% {
								fill: transparent;
							}
							50% {
								fill: rgba(#4CC3C6, 0.5);
							}
							100% {
								fill: transparent;
							}
						}
						animation-name: middle-animation;
						animation-duration: 4s;
						animation-iteration-count: infinite;
					}
				}

				.project-content {
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					flex: 1;
					gap: 2rem;

					.project-title {
						margin-bottom: 2rem;
						font-size: 1.5rem;
						font-weight: bold;
					}

					.project-text {
						font-size: 0.875rem;
						margin-bottom: 1.25rem;
					}
					.project-tech-stack {
						.tech-stack-subtitle {
							display: block;
							margin-bottom: 0.625rem;
							font-size: 0.875rem;
						}
						.tech-stack {
							font-weight: bold;
							font-size: 1rem;
						}
					}
				}
			}
		}
		@include media-breakpoint-down(xs) {
			.projects {
				flex-direction: column;

				.project {
					.project-content {
						.project-tech-stack {
							.tech-stack-subtitle {
								font-size: 1rem;
								margin-bottom: 0;
							}
							.tech-stack {
								font-size: 1.25rem;
							}
						}
					}
				}
			}
		}
	}
}
