@mixin Testimonials() {
	.testimonials-wrapper {
		padding-top: 5rem;
		padding-bottom: 6.25rem;

		.testimonials-subtitle {
			margin-bottom: 2rem;
		}

		.title {
			margin-bottom: 5rem;
		}

		.slider {
			padding-left: 10rem;
		}

		.testimonials-item {
			.item-image {
				width: 100%;
				margin-bottom: 2.5rem;
			}
			.item-text {
				margin-bottom: 2rem;
				opacity: 0.8;
				font-size: 1.125rem;
				font-style: italic;
			}
			.item-from {
				text-transform: unset;
				font-size: 1.125rem;
				font-weight: bold;
			}
		}

		@include media-breakpoint-down(md) {
			.slider {
				padding-left: 4rem;
			}
		}

		@include media-breakpoint-down(sm) {
			padding-top: 8rem;
			padding-bottom: 4rem;

			.testimonials-subtitle {
				margin-bottom: 1.5rem;
				transform: translateY(0px);
				opacity: 1;
		
				&:before {
					width: 40px;
				}
			}

			.title {
				font-size: 2rem;
				margin-bottom: 3rem;
			}

			.slider {
				width: 100%;
				padding-left: 0;

				.testimonials-item {
					padding: 0 25px;
				}
			}
		}
	}
}
